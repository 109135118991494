import React from 'react';
import { getPrizeType, mergePrizesList } from 'utils/helper';

export const PrizesTable = (props) => {
  const { state, prizes, currency } = props;
  const prizeTableList = mergePrizesList(prizes)

  return (
    <table>
      <thead>
      <tr>
        <th className='th_prizes_1'>#</th>
        <th className='th_5'>{state.translations?.prize}</th>
      </tr>
      </thead>
      <tbody>
      {prizeTableList.map((prizesItem) => {
        if (prizesItem.prizeTypeId) {
          const isSamePlace = prizesItem.startPlace === prizesItem.endPlace;
          const placeView = isSamePlace ? prizesItem.startPlace : `${prizesItem.startPlace} - ${prizesItem.endPlace}`

          return <tr key={prizesItem.place}>
            <td className='td_1'>{placeView}</td>
            <td className='td_5'>{getPrizeType(prizesItem, currency, state.translations?.xFreeRound)}</td>
          </tr>;
        }
      })}
      </tbody>
    </table>
  );
};