import React, { useContext } from 'react';
import { PlayerPositionIcon } from 'lib/Icons';
import styles from './styles.module.less';
import { AuthContext } from 'context/AuthContext';

const PlayerPosition = ({ position }) => {
  if (!position) {
    return null;
  }

  const [state] = useContext(AuthContext);

  return (
    <div className={styles.positionWrapper}>
      <span className={styles.icon}>
        <PlayerPositionIcon />
      </span>
      <div className={styles.text}>
        <span className={styles.playerText}>{state.translations?.myPosition || 'My Position'} -</span>
        <span className={styles.position}>
        {`${position || '-'}`}
      </span>
      </div>
    </div>
  );
};

export default PlayerPosition;