import React, { useContext } from 'react';
import { AuthContext } from 'context/AuthContext';
import { formatNumber } from 'utils/helper';
import styles from '../../../modal.module.less';

export const PlayerInfo = ({ myScore }) => {
  const [state] = useContext(AuthContext);

  return (
    <>
      <div className={styles.playerInfo}>
        <span>
          <span>{state.translations?.score}</span> - <span
          className={styles.scoreText}>{formatNumber(myScore?.score) || '-'}</span>
        </span>
      </div>
      <div className={styles.playerInfo}>
        <span>
           <span> {state.translations?.place} </span> - <span
          className={styles.scoreText}>{myScore?.place || '-'}</span>
        </span>
      </div>
    </>
  );
};