import React, { useContext } from 'react';
import { AuthContext } from 'context/AuthContext';
import classNames from 'classnames';
import styles from '../styles.module.less';

export default (props) => {
  const { tournament } = props;
  const [state] = useContext(AuthContext);

  return (
    <div className={styles.tournamentSingleRulesWrapper}>
      <div className={styles.tournamentSingleRulesTitle}>{state.translations?.tournamentRules}</div>
      <div
        className={classNames(styles.tournamentSingleRules)} // ql-editor
        dangerouslySetInnerHTML={{ __html: tournament?.description ?? '' }}
      />

      <div
        className={classNames(styles.tournamentSingleRules)} // ql-editor
        dangerouslySetInnerHTML={{ __html: tournament?.rules ?? '' }}
      />
    </div>
  );
}
