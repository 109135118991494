import bordersTypes from '../../utils/bordersTypes';
import {
  dispatchSetAuthToken,
  dispatchSetLanguageId,
  dispatchSetLayoutType,
  dispatchSetTestEnv,
  dispatchDeviceType,
} from '../../context/reducerActions';
import { setCardBGColor, setOpacityColor, setPHTextColor } from '../../utils/helper';

export const setConfigs = ({ data, dispatch }) => {
  if (data.type === 'setColor') {
    if (data.value.name === 'divider-color') return;

    if (data.value.name === 'text-color') {
      setPHTextColor(data.value.color);
      setOpacityColor({ color: data.value.color, colorName: 'divider-color', op: 0.1 });

    } else if (data.value.name === 'card-background-color') {
      setCardBGColor(data.value.color);
    }
    document.documentElement.style.setProperty(`--${data.value.name}`, data.value.color);
  }

  if (data.type === 'setLayout') {
    dispatchSetLayoutType(dispatch, data.value);
  }

  if (data.type === 'setBorder') {
    for (const name in bordersTypes[data.value]) {
      document.documentElement.style.setProperty(`${name}`, bordersTypes[data.value][name]);
    }
  }
};

const handleConfigJson = ({ config, dispatch }) => {
  Object.keys(config).forEach((configItem) => {
    if (configItem === 'setColors') {
      Object.keys(config[configItem]).forEach((colorItem) => {
        setConfigs({
          data: {
            type: 'setColor',
            value: {
              name: config[configItem][colorItem]?.name,
              color: config[configItem][colorItem]?.color,
            },
          }, dispatch,
        });
      });
    } else {
      setConfigs({
        data: { type: configItem, value: config[configItem] }, dispatch,
      });
    }
  });
};

export const handleParams = ({ searchParams, setSearchParams, dispatch }) => {
  if (!searchParams.has('testEnv')) {
    if (searchParams.has('config')) {
      try {
        handleConfigJson({ config: JSON.parse(searchParams.get('config')), dispatch });
        searchParams.delete('config');
      } catch (e) {
        return false;
      }
    }
    if (searchParams.has('authToken')) {
      dispatchSetAuthToken(dispatch, searchParams.get('authToken'));
      searchParams.delete('authToken');
    }

    if (searchParams.has('deviceType')) {
      dispatchDeviceType(dispatch, searchParams.get('deviceType'));
      searchParams.delete('deviceType');
    }

    if (searchParams.has('languageId')) {
      dispatchSetLanguageId(dispatch, searchParams.get('languageId'));
      searchParams.delete('languageId');
    }

  } else {
    dispatchSetAuthToken(dispatch, 'test');
    dispatchSetTestEnv(dispatch, true);
  }
};

export default ({ dispatch, navigate }) => {
  window.addEventListener('message', (e) => {
    const { data } = e;

    if (data.type === 'redirectToTournament') {
      navigate(`/tournament/test/Periods/test/?testEnv=true`);
    } else if (data.type === 'redirectToList') {
      navigate(`/?testEnv=true`);
    } else if (data.type === 'setLanguageId') {
      dispatchSetLanguageId(dispatch, data.value);
    } else if (data.type === 'curl') {
      navigate(decodeURIComponent(data.value));
    }

    setConfigs({ data, dispatch });
  });
}

export const msgToParent = (data) => {
  window.parent.postMessage({ data, dirChild: true }, '*');
};