import React, { useContext, useEffect, useState } from 'react';
import { Spin } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { msgToParent } from 'services/processPostMessage/ProcessPostMessage';
import Header from './components/Header';
import Games from './components/games/Games';
import Rules from './components/Rules';
import LeaderboardPrizes from './components/LeaderboardsAndPrizes';
import styles from './styles.module.less';
import Leaderboards from './components/leaderboards/Leaderboards';
import { ArrowLeftIcon } from 'lib/Icons';
import { useWebSocket } from '../../lib/hooks/useWebSocket';
import PeriodButtons from './components/PeriodButtons';
import useSingle from './hooks/useSingle';
import { transformPrizesData } from '../../lib/transformPrizesData';
import { AuthContext } from '../../context/AuthContext';
import { checkHasGameSection } from '../../utils/helper';
import Notice from './components/Notice';
import useGetBreakpoints from '../../lib/hooks/useGetBreakpoints';

const TournamentSingle = () => {
  const params = useParams();
  const tournamentId = params?.tournamentId;
  const periodId = params?.periodId;
  const [state] = useContext(AuthContext);
  const { leaderboardCount } = state.tournament;
  const [periodIdAndStatus, setPeriodIdAndStatus] = useState(null);
  const navigate = useNavigate();
  const { connect, disconnect } = useWebSocket({ tournamentId, leaderboardCount });
  const testEnv = state?.testEnv;
  const {
    isLoading, tournament,
    leaderboard, myScore,
    prizes, periods,
  } = useSingle(tournamentId, periodIdAndStatus, connect, disconnect, setPeriodIdAndStatus, periodId);

  const handleBackBtn = () => {
    if (!testEnv) {
      navigate('/');
    }
  };

  useEffect(() => {
    msgToParent({ type: 'scrollto', value: 0 });
  }, []);

  const transformedLeaderboard = transformPrizesData(leaderboard, prizes, myScore, tournament?.currency);
  const hasGameSection = checkHasGameSection(periodIdAndStatus);
  const isNative = navigator.userAgent.toLocaleLowerCase().includes('mobnative');

  const { checkBreakpoint } = useGetBreakpoints();
  const isMobile = checkBreakpoint(['sm']);

  const backGroundImage = isMobile ? tournament?.mobileImageUrl : tournament?.desktopImageUrl;

  return (
    <Spin spinning={isLoading}>
      <div className={styles.tournamentSinglePage}>
        <div
          className={styles.tournamentSingleTopBg}
          style={{ backgroundImage: `url(${backGroundImage})` }}
        >
          <div className={styles.tournamentSingleTopBgShadow} />
        </div>

        <div className={styles.tournamentSingleWrapper}>

          {!isNative && (
            <div className={styles.tournamentSingleLeft} onClick={handleBackBtn}>
              <ArrowLeftIcon />
            </div>
          )}

          <Header
            tournament={tournament}
            isLoading={isLoading}
          />

          <PeriodButtons
            tournament={tournament}
            periods={periods}
            testEnv={testEnv}
            setPeriodIdAndStatus={setPeriodIdAndStatus}
            periodId={periodIdAndStatus?.id}
          />

          <Leaderboards
            leaderboardData={transformedLeaderboard}
            currency={tournament?.currency}
            myScore={myScore}
            prizes={prizes}
          />

          <Notice notice={tournament?.notice} />

          {hasGameSection && (
            <Games
              tournament={tournament}
              periodId={periodIdAndStatus?.id}
              gameIdParam={params?.gameId}
              playTypeParam={params?.playType}
              testEnv={testEnv}
              prizes={prizes}
              myScore={myScore}
              leaderboardData={transformedLeaderboard}
              currency={tournament?.currency}
            />
          )}

          <Rules tournament={tournament} />

          <LeaderboardPrizes
            prizes={prizes}
            myScore={myScore}
            leaderboardData={transformedLeaderboard}
            currency={tournament?.currency}
          />
        </div>
      </div>
    </Spin>
  );
};

export default TournamentSingle;